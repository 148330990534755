<template>
    <b-row class="fixed-bottom text-right button-row justify-content-center" align-v="center">
        <b-col cols="11">
            <slot></slot>            
        </b-col>
    </b-row>
</template>


<script>
export default {
}
</script>

<style scoped lang="scss">
@import 'src/scss/main';

.button-row {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #FFF;
    height: $button-row-height;
    z-index: $z-index-button-row !important;
}
</style>